<template>
	<div>
		<ZyroButton
			v-if="!item.isHomepage"
			class="popup-button"
			data-qa="sitemenusettingspopup-btn-setashomepage"
			icon-left="home"
			@click="setAsHomepage(), toggle()"
		>
			{{ $t('common.setAsHomepage') }}
		</ZyroButton>
		<ZyroButton
			class="popup-button"
			data-qa="sitemenusettingspopup-btn-pagesettings"
			icon-left="settings"
			@click="openPageSettingsModal"
		>
			{{ $t('common.pageSettings') }}
		</ZyroButton>
		<ZyroButton
			class="popup-button"
			data-qa="sitemenusettingspopup-btn-rename"
			icon-left="rename"
			@click="$emit('start-renaming')"
		>
			{{ $t('common.rename') }}
		</ZyroButton>
		<DropdownControlButtons :item="item" />
		<ZyroButton
			class="popup-button"
			data-qa="sitemenusettingspopup-btn-duplicate"
			icon-left="duplicate"
			@click="duplicate(), toggle()"
		>
			{{ $t('common.duplicate') }}
		</ZyroButton>
		<HideItemButton
			:item="item"
			:toggle="toggle"
		/>
		<ZyroButton
			v-if="!item.isHomepage"
			class="popup-button"
			data-qa="sitemenusettingspopup-btn-delete"
			icon-left="trash"
			@click="isDeleteModalShown = true"
		>
			{{ $t('common.delete') }}
		</ZyroButton>
		<SystemDialogModal
			v-if="isDeleteModalShown"
			:title="$t('builder.deletePageModal.title')"
			:primary-button-text="$t('common.cancel')"
			:secondary-button-text="$t('common.delete')"
			@click-primary="isDeleteModalShown = false, toggle()"
			@click-secondary="removeItem"
			@close="isDeleteModalShown = false, toggle()"
		>
			<p class="z-body">
				{{ $t('builder.deletePageModal.text') }}
				<span class="z-body z-body--strong">{{ item.name }}</span>?
			</p>
		</SystemDialogModal>
	</div>
</template>

<script>
import { nanoid } from 'nanoid';
import {
	mapState,
	mapActions,
	mapMutations,
	mapGetters,
} from 'vuex';

import SystemDialogModal from '@/components/builder-modals/modals/SystemDialogModal.vue';
import {
	mapActionsGui,
	OPEN_MODAL,
} from '@/store/builder/gui';

import DropdownControlButtons from './-partials/DropdownControlButtons.vue';
import HideItemButton from './-partials/HideItemButton.vue';

export default {
	components: {
		DropdownControlButtons,
		HideItemButton,
		SystemDialogModal,
	},
	props: {
		item: {
			type: Object,
			required: true,
		},
		toggle: {
			type: Function,
			default: () => ({}),
		},
	},
	data() {
		return { isDeleteModalShown: false };
	},
	computed: {
		...mapState(['website']),
		...mapGetters('pages', ['defaultPages']),
	},
	methods: {
		...mapActionsGui({ openModal: OPEN_MODAL }),
		...mapMutations(['setWebsiteMeta']),
		...mapActions('pages', [
			'duplicatePage',
			'removePage',
			'setPageData',
		]),
		/**
		 * If old homepage had '/' path, generate a new, unique one for it,
		 * and set new homepageId in meta.
		 */
		setAsHomepage() {
			const { meta } = this.website;

			if (this.defaultPages[meta.homepageId].oldPath) {
				this.setPageData({
					type: 'default',
					payload: {
						data: { path: this.defaultPages[meta.homepageId].oldPath },
						pageId: meta.homepageId,
					},
				});
			} else {
				this.setPageData({
					type: 'default',
					payload: {
						data: { path: `/page-${nanoid()}` },
						pageId: meta.homepageId,
					},
				});
			}

			this.setPageData({
				type: 'default',
				payload: {
					data: {
						path: '/',
						oldPath: this.defaultPages[this.item.id].path,
					},
					pageId: this.item.id,
				},
			});
			this.setWebsiteMeta({
				key: 'homepageId',
				value: this.item.id,
			});
		},
		openPageSettingsModal() {
			this.openModal({
				name: 'PageSettingsModal',
				settings: {
					pageName: this.item.name,
					itemId: this.item.id,
				},
			});
		},
		duplicate() {
			this.duplicatePage({
				type: 'default',
				payload: {
					pageId: this.item.id,
					name: this.item.name,
					isHidden: this.item.isHidden,
				},
			});
		},
		removeItem() {
			this.removePage({
				type: 'default',
				payload: { pageId: this.item.id },
			});

			this.isDeleteModalShown = false;
		},
	},
};
</script>

<style lang="scss" scoped>
@import './PopupButton';
</style>
