import {
	ref,
	computed,
} from '@vue/composition-api';

import i18n from '@/i18n/setup';

export const defaultValidator = (value) => {
	const isValid = value.trim() !== '';

	return isValid
		? {
			isValid: true,
			error: '',
		}
		: {
			isValid: false,
			error: i18n.t('validate.emptyValue'),
		};
};

export const useEditableItemInput = (props, { emit }) => {
	const isInputShown = ref(false);
	const inputValue = ref('');
	const error = ref('');

	const handleInput = (newValue) => {
		const {
			error: inputError,
			isValid,
		} = props.validateValue(newValue);

		error.value = isValid ? '' : inputError;
		inputValue.value = newValue;
	};

	const setInputValue = (newValue) => {
		inputValue.value = newValue;
	};

	const showInput = () => {
		isInputShown.value = true;
	};

	const hideInput = () => {
		isInputShown.value = false;
		inputValue.value = '';
		error.value = '';
	};

	const submitValue = () => {
		if (!inputValue.value) {
			hideInput();

			return;
		}

		if (error.value) {
			return;
		}

		emit('add', inputValue.value);
		hideInput();
	};

	return {
		handleInput,
		showInput,
		hideInput,
		submitValue,
		setInputValue,
		isInputShown: computed(() => isInputShown.value),
		inputValue: computed(() => inputValue.value),
		error: computed(() => error.value),
	};
};
