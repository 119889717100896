<template>
	<div
		v-qa="'builder-sidemenu-websitemenu'"
		class="multipage-drawer"
	>
		<div class="multipage-drawer__header">
			<h2 class="multipage-drawer__title z-h5">
				{{ $t('builder.siteMenu.title') }}
			</h2>
			<p class="multipage-drawer__description z-body-small">
				{{ $t('builder.siteMenu.description') }}
			</p>
		</div>
		<div
			ref="content"
			class="multipage-drawer__content"
			:class="isDialogOpen && 'multipage-drawer__content--no-scroll'"
		>
			<DrawerList
				:navigation-items="rootEditableItems"
				:title="$t('builder.siteMenu.mainNavigation')"
				:group-id="NAVIGATION_GROUP_ROOT"
				:move-callback="handleItemMove"
				@edit="edit"
				@draggable-end="handleItemsSort"
				@item-click="handleItemClick"
			>
				<template #placeholder>
					<EmptyPagesBlock
						:title="$t('builder.siteMenu.noMainPages')"
						:subtitle="$t('builder.siteMenu.addMainPage')"
					/>
				</template>
			</DrawerList>
			<DrawerList
				:navigation-items="hiddenEditableItems"
				:title="$t('builder.siteMenu.otherPages')"
				:group-id="NAVIGATION_GROUP_HIDDEN"
				:move-callback="handleItemMove"
				:subtitle="$t('builder.siteMenu.otherPagesSubtitle')"
				@edit="edit"
				@draggable-end="handleItemsSort"
				@item-click="handleItemClick"
			>
				<template #placeholder>
					<EmptyPagesBlock
						:title="$t('builder.siteMenu.noOtherPages')"
						:subtitle="$t('builder.siteMenu.addOtherPage')"
					/>
				</template>
			</DrawerList>
		</div>
		<div class="multipage-drawer__footer">
			<ZyroButton
				v-qa="'builderheader-menuitemhome-addnewpage'"
				theme="outline"
				icon-left="plus"
				@click="openModal({ name: 'AddPageModal' })"
			>
				{{ $t('common.addPage') }}
			</ZyroButton>
			<div>
				<ZyroButton
					v-qa="'builderheader-menuitemhome-addnewfolder'"
					theme="outline"
					class="multipage-drawer__footer-dropdown-button"
					:title="$t('common.addDropdown')"
					icon="dropdown-add"
					@click="addFolder"
				/>
				<ZyroButton
					v-qa="'builderheader-menuitemhome-addnewlink'"
					theme="outline"
					:title="$t('common.addLink')"
					icon="link-add"
					@click="openModal({ name: 'AddLinkModal' })"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import {
	ref,
	onMounted,
} from '@vue/composition-api';
import { mapActions } from 'vuex';

import {
	mapActionsGui,
	OPEN_MODAL,
} from '@/store/builder/gui';
import {
	NAVIGATION_PAGE_TYPE,
	NAVIGATION_LINK_TYPE,
	NAVIGATION_FOLDER_TYPE,
	NAVIGATION_GROUP_ROOT,
	NAVIGATION_GROUP_HIDDEN,
} from '@/use/navigation/constants';
import { useNavigationEditableItems } from '@/use/navigation/useNavigationEditableItems';
import { isDesktopSafari } from '@/utils/browserIdentifiers';

import DrawerList from './DrawerList.vue';
import EmptyPagesBlock from './EmptyPagesBlock.vue';

export default {
	components: {
		DrawerList,
		EmptyPagesBlock,
	},
	setup() {
		const {
			rootEditableItems,
			hiddenEditableItems,
			handleItemsSort,
		} = useNavigationEditableItems();

		const content = ref(null);
		const isDialogOpen = ref(false);
		/**
		 * This is a Safari hack!
		 *
		 * Popups in the drawer are being closed on click-outside.
		 * Modals cannot be used with <Portal>,
		 * because they must be inside <Popup/> to prevent instant closing.
		 *
		 * This breaks in Safari - when scrollbar appear on smaller screens,
		 * stacking context changes and fixed modal repositions inside drawer instead of document.
		 *
		 * There are multiple such dialogs, so to prevent massive prop drilling
		 * I just used MutationObserver to watch for DOM changes in the component ref.
		 *
		 */

		onMounted(() => {
			if (!isDesktopSafari) {
				return;
			}

			const dialogObserver = new MutationObserver(() => {
				const dialog = content.value.querySelector('.modal');

				isDialogOpen.value = !!dialog;
			});

			dialogObserver.observe(content.value, {
				subtree: true,
				childList: true,
			});
		});

		return {
			content,
			isDialogOpen,
			rootEditableItems,
			hiddenEditableItems,
			handleItemsSort,
			NAVIGATION_GROUP_ROOT,
			NAVIGATION_GROUP_HIDDEN,
			NAVIGATION_PAGE_TYPE,
			NAVIGATION_LINK_TYPE,
		};
	},
	methods: {
		...mapActionsGui({ openModal: OPEN_MODAL }),
		...mapActions('navigation', [
			'setItemData',
			'moveItem',
			'addItem',
		]),
		...mapActions('pages', [
			'setPageData',
			'changePage',
		]),
		handleItemMove({
			toId,
			item,
		}) {
			// Allow moving item (which is not a folder) without subitems to anywhere
			if (item.type !== NAVIGATION_FOLDER_TYPE && item.subItemsIds.length === 0) {
				return true;
			}

			if (toId === NAVIGATION_GROUP_ROOT || toId === NAVIGATION_GROUP_HIDDEN) {
				return true;
			}

			return false;
		},
		edit({ newValue }) {
			const {
				id,
				name,
				type,
			} = newValue;

			if (type === NAVIGATION_PAGE_TYPE) {
				this.setPageData({
					type: 'default',
					payload: {
						data: { name },
						pageId: id,
					},
				});
			}

			if (type === NAVIGATION_LINK_TYPE || type === NAVIGATION_FOLDER_TYPE) {
				this.setItemData({
					data: { name },
					itemId: id,
				});
			}
		},
		handleItemClick({
			type,
			id,
		}) {
			if (type === NAVIGATION_PAGE_TYPE) {
				this.changePage({ pageId: id });
			}
		},
		addFolder() {
			this.addItem({
				item: {
					type: NAVIGATION_FOLDER_TYPE,
					name: this.$t('common.newDropdown'),
					subItems: [],
				},
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.multipage-drawer {
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: hidden;

	&__header {
		flex: 0;
		width: 100%;
		padding: 24px 24px 0;
	}

	&__title {
		margin-bottom: 8px;
	}

	&__description {
		color: $grey-600;
	}

	&__content {
		flex: 1;
		overflow-x: hidden;
		overflow-y: auto;

		&--no-scroll {
			overflow: hidden;
		}
	}

	&__footer {
		display: flex;
		justify-content: space-between;
		width: 100%;
		padding: 16px 24px;
		margin-top: auto;
	}

	&__footer-dropdown-button {
		margin-right: 8px;
	}
}
</style>
