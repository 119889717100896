<template>
	<div>
		<ZyroButton
			class="popup-button"
			data-qa="sitemenusettingspopup-btn-linksettings"
			icon-left="settings"
			@click="openLinkSettingsModal"
		>
			{{ $t('common.linkSettings') }}
		</ZyroButton>
		<ZyroButton
			class="popup-button"
			data-qa="sitemenusettingspopup-btn-rename"
			icon-left="rename"
			@click="$emit('start-renaming')"
		>
			{{ $t('common.rename') }}
		</ZyroButton>
		<DropdownControlButtons :item="item" />
		<ZyroButton
			class="popup-button"
			data-qa="sitemenusettingspopup-btn-duplicate"
			icon-left="duplicate"
			@click="duplicate(), toggle()"
		>
			{{ $t('common.duplicate') }}
		</ZyroButton>
		<HideItemButton
			:item="item"
			:toggle="toggle"
		/>
		<ZyroButton
			class="popup-button"
			data-qa="sitemenusettingspopup-btn-delete"
			icon-left="trash"
			@click="isDeleteModalShown = true"
		>
			{{ $t('common.delete') }}
		</ZyroButton>
		<SystemDialogModal
			v-if="isDeleteModalShown"
			:title="$t('builder.deletePageModal.linkTitle')"
			:primary-button-text="$t('common.cancel')"
			:secondary-button-text="$t('common.delete')"
			@click-primary="isDeleteModalShown = false, toggle()"
			@click-secondary="remove"
			@close="isDeleteModalShown = false, toggle()"
		>
			<p class="z-body">
				{{ $t('builder.deletePageModal.text') }}
				<span class="z-body z-body--strong">{{ item.name }}</span>?
			</p>
		</SystemDialogModal>
	</div>
</template>

<script>
import { mapActions } from 'vuex';

import SystemDialogModal from '@/components/builder-modals/modals/SystemDialogModal.vue';
import {
	mapActionsGui,
	OPEN_MODAL,
} from '@/store/builder/gui';

import DropdownControlButtons from './-partials/DropdownControlButtons.vue';
import HideItemButton from './-partials/HideItemButton.vue';

export default {
	components: {
		DropdownControlButtons,
		HideItemButton,
		SystemDialogModal,
	},
	props: {
		item: {
			type: Object,
			required: true,
		},
		toggle: {
			type: Function,
			default: () => ({}),
		},
	},
	data() {
		return { isDeleteModalShown: false };
	},
	methods: {
		...mapActionsGui({ openModal: OPEN_MODAL }),
		...mapActions('navigation', [
			'duplicateItem',
			'removeItem',
		]),
		openLinkSettingsModal() {
			this.openModal({
				name: 'LinkSettingsModal',
				settings: {
					itemId: this.item.id,
					pageName: this.item.name,
				},
			});
		},
		duplicate() {
			this.duplicateItem({ itemId: this.item.id });
		},
		remove() {
			this.removeItem({ itemId: this.item.id });
			this.isDeleteModalShown = false;
			this.toggle();
		},
	},
};
</script>

<style lang="scss" scoped>
@import './PopupButton';
</style>
