import {
	computed,
	getCurrentInstance,
} from '@vue/composition-api';

import {
	NAVIGATION_PAGE_TYPE,
	NAVIGATION_GROUP_ROOT,
	NAVIGATION_GROUP_HIDDEN,
	NAVIGATION_ICONS,
	NAVIGATION_LINK_TYPE,
	NAVIGATION_HOMEPAGE_TYPE,
} from '@/use/navigation/constants';

export const useNavigationEditableItems = () => {
	const {
		state,
		getters,
		dispatch,
	} = getCurrentInstance().$store;
	const {
		website,
		pages,
	} = state;
	const defaultPages = computed(() => getters['pages/defaultPages']);
	const homepageId = computed(() => website.meta.homepageId);
	const currentPageId = computed(() => pages.currentPageId);
	const items = computed(() => getters['navigation/items']);
	const itemsIds = computed(() => getters['navigation/itemsIds']);
	const hiddenItemsIds = computed(() => getters['navigation/hiddenItemsIds']);
	const getGroupItemsByGroupName = computed(() => getters['navigation/getGroupItemsByGroupName']);

	const getItemData = (id, parentId) => {
		const {
			type,
			name,
			subItems,
		} = items.value[id];

		const isHomepage = homepageId.value === id;
		const {
			icon,
			title: iconTitle,
		} = NAVIGATION_ICONS[isHomepage ? NAVIGATION_HOMEPAGE_TYPE : type];

		const isHidden = hiddenItemsIds.value.includes(id);
		const isRootItem = itemsIds.value.includes(id);
		const groupId = parentId || (isHidden ? NAVIGATION_GROUP_HIDDEN : NAVIGATION_GROUP_ROOT);
		const pageName = type === NAVIGATION_PAGE_TYPE ? defaultPages.value[id].name : name;

		const canBeDraggedToItemAbove = () => {
			const indexInRoot = itemsIds.value.indexOf(id);
			const isMovableRootItem = indexInRoot && indexInRoot !== 0 && indexInRoot !== -1;
			const allowedMovableTypes = [
				NAVIGATION_LINK_TYPE,
				NAVIGATION_PAGE_TYPE,
			];

			if (!isMovableRootItem || !allowedMovableTypes.includes(type)) {
				return {
					canBeMovedToDropdown: false,
					itemAboveId: null,
				};
			}

			const itemAboveId = itemsIds.value[indexInRoot - 1];

			return {
				canBeMovedToDropdown: subItems.length === 0,
				itemAboveId,
			};
		};

		const {
			canBeMovedToDropdown,
			itemAboveId,
		} = canBeDraggedToItemAbove();

		return {
			// Attributes for EditableItemsList
			id,
			icon,
			iconTitle,
			isCurrent: currentPageId.value === id,
			vQa: `sitemenu-page-${pageName}`,
			subItems: subItems.map((subItemId) => getItemData(subItemId, id)),
			hasNoSubList: !isRootItem || isHidden,
			// Attributes for item popup
			type,
			isHidden,
			isHomepage,
			isRootItem,
			index: getGroupItemsByGroupName.value(groupId).indexOf(id),
			parentIndex: getGroupItemsByGroupName.value('ROOT').indexOf(parentId),
			groupId,
			itemAboveId,
			canBeMovedToDropdown,
			subItemsIds: subItems,
			name: pageName,
		};
	};

	const rootEditableItems = computed(() => itemsIds.value.map((id) => getItemData(id)));

	const hiddenEditableItems = computed(() => hiddenItemsIds.value.map((id) => getItemData(id)));

	const handleItemsSort = ({
		fromId,
		toId,
		itemId,
		oldIndex,
		newIndex,
	}) => {
		if (fromId === toId) {
			const groupToSort = getGroupItemsByGroupName.value(fromId);
			const oldItem = groupToSort[oldIndex];

			groupToSort.splice(oldIndex, 1);
			groupToSort.splice(newIndex, 0, oldItem);

			dispatch('navigation/setGroupData', {
				groupId: fromId,
				data: groupToSort,
			});

			return;
		}

		const { subItems } = items.value[itemId];

		// If moving a list with subitems to hidden list, flatten the data
		if (toId === NAVIGATION_GROUP_HIDDEN && subItems.length > 0) {
			dispatch('navigation/hideItemWithSubItems', {
				itemId,
				oldIndex,
				newIndex,
			});

			return;
		}

		dispatch('navigation/moveItem', {
			fromId,
			toId,
			oldIndex,
			newIndex,
		});
	};

	return {
		rootEditableItems,
		hiddenEditableItems,
		handleItemsSort,
	};
};
