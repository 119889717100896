<template>
	<form
		v-click-outside="{
			handler: () => $emit('blur'),
			events: ['mousedown'],
		}"
		class="item-editor"
		data-qa="edit-items-item-editor-form"
		@submit.prevent="$emit('submit')"
	>
		<ZyroFieldText
			color="light"
			class="item-editor__input"
			padding="7px"
			:error="error"
			:placeholder="placeholder"
			:value="value"
			focus-on-mount
			data-qa="edititems-item-inputfield-newitem"
			@input="$emit('input', $event.target.value)"
		/>
		<ZyroButton
			color="black"
			theme="primary"
			icon="check-mark-thick"
			class="item-editor__button"
			border-radius="0px"
			type="submit"
			data-qa="edititems-item-btn-additem"
		/>
	</form>
</template>

<script>
export default {
	props: {
		value: {
			type: String,
			default: '',
		},
		placeholder: {
			type: String,
			default: '',
		},
		error: {
			type: String,
			default: '',
		},
	},
};
</script>

<style lang="scss" scoped>
.item-editor {
	display: grid;
	grid-template-columns: 1fr auto;
	grid-column: 1/-1;
	grid-gap: 10px;
	align-items: flex-start;
	width: 100%;
	padding: 12px;
	background: $grey-100;

	&__input {
		margin-bottom: 0;
	}

	&__button {
		overflow: hidden;
	}
}
</style>
