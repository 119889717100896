<template>
	<!-- You need to wrap the button in a div if you want to test click event :') -->
	<div class="popup-button">
		<ZyroButton
			:data-qa="`sitemenusettingspopup-btn-${item.isHidden ? 'show' : 'hide'}`"
			:icon-left="`eye-${item.isHidden ? 'open' : 'closed'}`"
			@click="hideNavigationItem"
		>
			{{ item.isHidden ? $t('common.show') : $t('builder.siteMenu.removeFromNavigation') }}
		</ZyroButton>
	</div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
	props: {
		item: {
			type: Object,
			required: true,
		},
		toggle: {
			type: Function,
			default: () => ({}),
		},
	},
	methods: {
		...mapActions('navigation', [
			'showItem',
			'hideItem',
		]),
		hideNavigationItem() {
			if (this.item.isHidden) {
				this.showItem({ itemId: this.item.id });
			} else {
				this.hideItem({ itemId: this.item.id });
			}

			this.toggle();
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/components/builder-controls/multipage-drawer/popup-buttons/PopupButton';
</style>
