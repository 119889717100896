<template>
	<div class="items">
		<slot name="header" />
		<div
			ref="items"
			:class="{ 'items-list-scrollable': isScrollable }"
		>
			<!-- `force-fallback` needs a binded true value, otherwise it doesn't get set -->
			<Draggable
				v-model="currentItems"
				tag="ul"
				:group="{ name: groupName }"
				:data-group-id="groupId"
				:class="{ 'items-list-inner--scrollable': isScrollable }"
				class="items-list-inner"
				chosen-class="item-ghost"
				ghost-class="item-ghost"
				drag-class="item-ghost-dragged"
				handle=".item__drag-handle"
				direction="vertical"
				animation="150"
				easing="cubic-bezier(0.76, 0, 0.24, 1)"
				:force-fallback="true"
				:inverted-swap-threshold="0.4"
				:swap-threshold="0.4"
				:empty-insert-threshold="4"
				:move="handleMove"
				@end="handleDragEnd"
			>
				<li
					v-for="(item, index) in currentItems"
					:key="`${item.name}-item-${index}`"
					v-qa="`editableitemlist-${item.name || item.id}-itemcontainer`"
					:data-item-id="item.id || item.name"
					class="item-container"
				>
					<div
						class="item"
						:class="{
							'item--with-icon': item.icon,
							'item--disabled': item.isDisabled,
							'item--active': currentItemIndex === index,
							'item--selected': currentSelectedItemIndex === index,
							'item--before-active': currentItemIndex === index + 1
						}"
						@click="$emit('item-click', item)"
					>
						<EditableItemsItemEditor
							v-if="index === currentItemIndex"
							:value="inputValue"
							:placeholder="placeholder"
							:error="error"
							@submit="editCurrentItem"
							@input="handleInput"
							@blur="stopEditing"
						/>
						<template v-else>
							<ZyroButton
								class="item__drag-handle"
								theme="plain"
								color="black"
								icon="move"
								:title="$t('common.move')"
							/>
							<ZyroSvg
								v-if="item.icon"
								class="item__icon"
								:location="item.iconLocation"
								:name="item.icon"
								:title="item.iconTitle"
							/>
							<p
								v-qa="`${item.vQa}`"
								class="z-body-small item__content"
								:class="{ 'z-body-small--strong': item.isCurrent }"
								@dblclick="startEditingItem(index, item)"
							>
								{{ item.name }}
							</p>
							<div :class="{ 'item__right-side-button' : isScrollable }">
								<slot
									name="item-button"
									:item="item"
									:index="index"
									:start-editing-item="() => startEditingItem(index, item)"
								/>
							</div>
						</template>
					</div>
					<div
						v-if="!item.hasNoSubList && item.subItems"
						class="item-container__subitems"
					>
						<EditableItemsList
							v-bind="{
								...$props,
								items: item.subItems,
								groupId: item.id,
							}"
							v-on="$listeners"
						>
							<!--
								Pass scoped slots down to the subitems list
								(https://gist.github.com/loilo/73c55ed04917ecf5d682ec70a2a1b8e2)
							-->
							<template
								v-for="(key, name) in $scopedSlots"
								:slot="name"
								slot-scope="slotData"
							>
								<slot
									:name="name"
									v-bind="slotData"
								/>
							</template>
						</EditableItemsList>
					</div>
				</li>
			</Draggable>
		</div>
	</div>
</template>

<script>
/**
 * EditableItemsList.vue
 * Component for sorting an array of object and editing them. Supports multi-level lists.
 * Events:
 * @item-click - item in a list is clicked.
 * @update-items - returns updated array of items after sorting an element.
 * @edit - item in a list has been edited. Returns (itemBeforeEdit, itemAfterEdit, itemArrayIndex).
 * @draggable-end - drag event has ended. Check `handleDragEnd` for return value.
 * @draggable-add - add event has ended. Check `handleDragAdd` for return value.
 *
 * Slots:
 *  header: allows to add custom header to the list.
 *  item-button: allows to add a custom button/any element to the most right of the button.
 *   Exposed slotted attributes:
 *    item (Object). Data of the current item.
 *    index (Number). Index of the current item in the list.
 *    startEditingItem (Function). Triggers the start of editing the current item.
 */
import Draggable from 'vuedraggable-axis';

import {
	useEditableItemInput,
	defaultValidator,
} from '@/components/reusable-components/editable-items-list/useEditableItemInput';

import EditableItemsItemEditor from './-partials/EditableItemsItemEditor.vue';

export default {
	// Recursive usage of a component needs a defined name
	name: 'EditableItemsList',
	components: {
		EditableItemsItemEditor,
		Draggable,
	},
	props: {
		/**
		 * Array of objects be rendered. The structure is as follows:
		 *
		 * Required attributes:
		 *  name: (String, Required). Value that will be showed as item content.
		 *
		 * Optional attributes:
		 *  id (String). Unique identifier for the item. Fallbacks to `name`.
		 *  icon (String). Icon for the item.
		 *  iconLocation (String). Attribute passed to ZyroSvg.vue.
		 *  iconTitle (String). Attribute passed to ZyroSvg.vue.
		 *  isDisabled (Boolean). Determines whether disabled styles should be applied to the item.
		 *  isCurrent (Boolean). Determines whether currently selected item styles should be applied.
		 *  vQa (String). v-qa directive value for the item.
		 *  subItems (Array of items). Items to be displayed as a sublist of the item.
		 *  hasNoSubList (Boolean). Used in conjunction with subItems. If item has subItems attribute,
		 *    but subItems array is empty, an empty list still exists so items could be dragged into it.
		 *    But, if this attribute is active, sublist won't be created.
		 *    This can be used to limit the depth of the lists.
		 */
		items: {
			type: Array,
			default: () => [],
			validator: (value) => {
				if (value.length > 0) {
					return value.every((item) => 'name' in item);
				}

				return true;
			},
		},
		/**
		 * Value to be shown when edit input field is empty
		 */
		placeholder: {
			type: String,
			default: '',
		},
		/**
		 * Determines wether items in the list can be edited
		 */
		isEditable: {
			type: Boolean,
			default: true,
		},
		/**
		 * Determines wether the list should have a maximum height (300px) and when it reaches it,
		 * become scrollable.
		 */
		scrollable: {
			type: Boolean,
			default: true,
		},
		/**
		 * "VueDraggable" list group name.
		 * Useful when using `handleAdd` even to determine
		 * the name of the list to which the item has been addded.
		 */
		groupName: {
			type: String,
			default: 'draggable-items',
		},
		/**
		 * Returned by the `handleAdd` and `handleDragEnd` events.
		 * This is the ID which is applied to the ROOT list.
		 * All sublists get the ID of the parent item.
		 */
		groupId: {
			type: String,
			default: 'draggable-items',
		},
		/**
		 * "VueDraggable" move callback.
		 * https://github.com/SortableJS/Vue.Draggable#move
		 * Useful to control to which items dragging should be allowed.
		 * Check handleMove for passed event data
		 */
		moveCallback: {
			type: Function,
			default: null,
		},
		/**
		 * Custom validator for the input.
		 * If validation fails, error message is shown and submit of editing is not allowed.
		 * By default, validator checks if the value is empty.
		 */
		validateValue: {
			type: Function,
			default: defaultValidator,
		},
		currentSelectedItemIndex: {
			type: Number,
			default: null,
		},
	},
	setup(props, context) {
		const {
			handleInput,
			setInputValue,
			inputValue,
			error,
		} = useEditableItemInput(props, context);

		return {
			handleInput,
			setInputValue,
			inputValue,
			error,
		};
	},
	data() {
		return { currentItemIndex: null };
	},
	computed: {
		currentItems: {
			get() {
				return this.items;
			},
			set(updatedList) {
				this.$emit('update-items', updatedList);
			},
		},
		/**
		 * Temporary hack until we have positioning issues fixed.
		 * If list items have a button with popup, popup gets hidden and unclickable because
		 * of `overflow: scroll`. 5 items is an approximate value until list gets scrollable
		 * and when it should have `overflow: scroll`.
		 *
		 */
		isScrollable() {
			return this.scrollable && this.items.length >= 5;
		},
	},
	methods: {
		startEditingItem(index, { name }) {
			if (!this.isEditable) {
				return;
			}

			this.currentItemIndex = index;
			this.setInputValue(name);
		},
		stopEditing() {
			this.currentItemIndex = null;
			this.hideInput();
		},
		editCurrentItem() {
			if (this.error) {
				return;
			}

			const itemBeforeEdit = this.currentItems[this.currentItemIndex];

			this.$emit('edit', {
				oldValue: itemBeforeEdit,
				newValue: {
					...itemBeforeEdit,
					name: this.inputValue,
				},
				index: this.currentItemIndex,
			});
			this.setInputValue('');
			this.currentItemIndex = null;
		},
		/**
		 * Parses out data from sortable event.
		 * Returns:
		 *  fromId - ID of the group from which the element was moved
		 *  toId - ID of the group to which the element was moved
		 *  itemId - ID of the item that was moved
		 *  oldIndex - index of the item in the old group array
		 *  newIndex - index of the item in the new group array
		 */
		getSortableEventData(sortableEvent) {
			const {
				from,
				to,
				item,
				oldIndex,
				newIndex,
			} = sortableEvent;
			const fromId = from.dataset.groupId;
			const toId = to.dataset.groupId;
			const { itemId } = item.dataset;

			return {
				fromId,
				toId,
				itemId,
				oldIndex,
				newIndex,
			};
		},
		handleMove(draggableEvent) {
			if (!this.moveCallback) {
				return null;
			}

			const toId = draggableEvent.to.dataset.groupId;
			const fromId = draggableEvent.from.dataset.groupId;
			const item = draggableEvent.draggedContext.element;

			return this.moveCallback({
				fromId,
				toId,
				item,
			});
		},
		handleDragEnd(sortableEvent) {
			this.$emit('draggable-end', this.getSortableEventData(sortableEvent));
		},
	},
};
</script>

<style lang="scss" scoped>
.items-list-inner {
	display: flex;
	flex-direction: column;
	width: 100%;
	list-style: none;

	&--scrollable {
		max-height: 300px;
	}
}

.items-list-scrollable {
	padding-right: 8px;
	overflow-y: scroll;
}

.items {
	&__add-item {
		margin-bottom: 1px;
	}

	&__add-item-button {
		width: 100%;
	}

	&__add-item-button-container {
		position: relative;
		padding: 20px 0 20px;
		margin-left: 4px;
	}
}

.item {
	$this: &;

	position: relative;
	display: grid;
	grid-template-columns: auto minmax(auto, 200px) auto;
	grid-gap: 8px;
	align-items: center;
	padding: 12px 0;
	cursor: pointer;
	border-bottom: 1px solid $grey-300;

	&__drag-handle {
		min-width: 24px;
		overflow: hidden;
		cursor: move;
	}

	&__icon {
		max-width: 16px;
	}

	&__content {
		margin-bottom: 0;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&__right-side-button {
		margin-left: auto;
		overflow: hidden;
	}

	&--before-active {
		margin-bottom: 1px;
		border-bottom: none;
	}

	&--active {
		padding: 0;
		margin-bottom: 1px;
		border-bottom: none;
	}

	&--selected {
		background-color: $grey-100;
		border: 1px solid $grey-800;
	}

	&--with-icon {
		grid-template-columns: auto 24px minmax(auto, 200px) auto;
	}

	&--disabled {
		color: $grey-700;
	}

	&:hover,
	&:focus {
		background: $grey-100;
		transition: background 0.3s ease 0s;
	}
}

.item-container {
	&__subitems {
		margin-left: 24px;

		.item {
			padding-right: 24px;
		}
	}
}

.item-ghost {
	background: $grey-200;
}

.item-ghost-dragged {
	background: $grey-200;
	opacity: 0.8;
}
</style>
