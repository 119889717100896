<template>
	<div class="popup-button">
		<ZyroButton
			v-if="!item.isRootItem && !item.isHidden"
			data-qa="sitemenusettingspopup-btn-move-out-dropdown"
			icon-left="arrow-corner-down-left"
			@click="moveOutOfDropdown"
		>
			{{ $t('builder.siteMenu.moveOutOfDropdown') }}
		</ZyroButton>
		<ZyroButton
			v-if="item.canBeMovedToDropdown"
			data-qa="sitemenusettingspopup-btn-move-to-dropdown"
			icon-left="arrow-corner-down-right"
			@click="moveToDropdown"
		>
			{{ $t('builder.siteMenu.moveToDropdown') }}
		</ZyroButton>
	</div>
</template>

<script>
import {
	mapActions,
	mapGetters,
} from 'vuex';

import { NAVIGATION_GROUP_ROOT } from '@/use/navigation/constants';

export default {
	props: {
		item: {
			type: Object,
			required: true,
		},
	},
	computed: mapGetters('navigation', ['items']),
	methods: {
		...mapActions('navigation', ['moveItem']),
		moveOutOfDropdown() {
			this.moveItem({
				fromId: this.item.groupId,
				toId: NAVIGATION_GROUP_ROOT,
				oldIndex: this.item.index,
				newIndex: this.item.parentIndex + 1,
			});
		},
		moveToDropdown() {
			this.moveItem({
				fromId: this.item.groupId,
				toId: this.item.itemAboveId,
				oldIndex: this.item.index,
				newIndex: this.items[this.item.itemAboveId].subItems.length,
			});
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/components/builder-controls/multipage-drawer/popup-buttons/PopupButton';
</style>
