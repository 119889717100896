<template>
	<div class="page-list">
		<div
			v-if="title"
			class="page-list__title z-body-small z-body-small--strong"
		>
			{{ title }}
		</div>
		<div
			v-if="subtitle"
			class="page-list__description z-body-small"
		>
			{{ subtitle }}
		</div>
		<EditableItemsList
			ref="items"
			class="page-list__list"
			:class=" { 'page-list__list--empty': isEmpty }"
			:items="navigationItems"
			:scrollable="false"
			:placeholder="$t('builder.blog.blogPostSettings.writeCategories')"
			:group-id="groupId"
			:move-callback="moveCallback"
			v-on="$listeners"
		>
			<template slot="header">
				<Transition
					v-if="isEmpty"
					appear
					name="fade"
				>
					<div class="page-list__empty-block">
						<slot name="placeholder" />
					</div>
				</Transition>
			</template>
			<template
				#item-button="{ item, index, startEditingItem }"
			>
				<DrawerListItemPopup
					:item="item"
					:on-popup-open="handlePopupOpen"
					:popup-opens-to-bottom="popupOpensToBottom"
					@start-renaming="startEditingItem(index, item)"
				/>
			</template>
		</EditableItemsList>
	</div>
</template>

<script>
import EditableItemsList from '@/components/reusable-components/editable-items-list/EditableItemsList.vue';

import DrawerListItemPopup from './DrawerListItemPopup.vue';

export default {
	components: {
		DrawerListItemPopup,
		EditableItemsList,
	},
	props: {
		title: {
			type: String,
			default: '',
		},
		subtitle: {
			type: String,
			default: '',
		},
		navigationItems: {
			type: Array,
			default: () => [],
		},
		groupId: {
			type: String,
			default: null,
		},
		moveCallback: {
			type: Function,
			default: null,
		},
	},
	data() {
		return { popupOpensToBottom: false };
	},
	computed: { isEmpty: ({ navigationItems }) => navigationItems.length === 0 },
	methods: {
		handlePopupOpen(popupTriggerPosition) {
			const {
				height: scrollAreaHeight,
				top: scrollAreaTop,
			} = this.$parent.$refs.content.getBoundingClientRect();
			const popupTopPositionInScrollArea = popupTriggerPosition.top - scrollAreaTop;
			/*
			 * By default, popup should be opened on the bottom of the item,
			 * unless it's not enough space for popup to fit.
			 * Example:
			 * Open popup of the last item in scroll and it won't be visible, you'd have to scroll down
			 *
			 * Edge case: if user screen has an unnatural height screen open the menu to the bottom.
			 */
			const RESERVED_SPACE_FOR_POPUP = 250;
			const MIN_SCROLL_HEIGHT = 430;

			this.popupOpensToBottom = scrollAreaHeight < MIN_SCROLL_HEIGHT
				|| scrollAreaHeight > popupTopPositionInScrollArea + RESERVED_SPACE_FOR_POPUP;
		},
	},
};
</script>

<style lang="scss" scoped>
.page-list {
	width: 100%;
	margin-top: 32px;

	&__title,
	&__description {
		padding: 0 24px;
		padding-bottom: 8px;
	}

	&__description {
		color: $grey-800;
	}

	&__list {
		position: relative;
		min-height: 100%;

		/**
		* Don't want to clutter editItemsList with edge-case handling style props,
		* so styling from parent
		*/
		&--empty ::v-deep .items-list-inner {
			min-height: 64px;
		}
	}

	&__empty-block {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		pointer-events: none;
	}
}
</style>
