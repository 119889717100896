<template>
	<div>
		<ZyroButton
			class="popup-button"
			data-qa="sitemenusettingspopup-btn-rename"
			icon-left="rename"
			@click="$emit('start-renaming')"
		>
			{{ $t('common.rename') }}
		</ZyroButton>
		<HideItemButton
			:item="item"
			:toggle="toggle"
		/>
		<ZyroButton
			class="popup-button"
			data-qa="sitemenusettingspopup-btn-delete"
			icon-left="trash"
			@click="isDeleteModalShown = true"
		>
			{{ $t('common.delete') }}
		</ZyroButton>
		<SystemDialogModal
			v-if="isDeleteModalShown"
			:title="$t('builder.deletePageModal.folderTitle')"
			:primary-button-text="$t('common.cancel')"
			:secondary-button-text="$t('common.delete')"
			@click-primary="isDeleteModalShown = false, toggle()"
			@click-secondary="remove"
			@close="isDeleteModalShown = false, toggle()"
		>
			<p class="z-body">
				{{ $t('builder.deletePageModal.text') }}
				<span class="z-body z-body--strong">{{ item.name }}</span>?
			</p>
		</SystemDialogModal>
	</div>
</template>

<script>
import { mapActions } from 'vuex';

import SystemDialogModal from '@/components/builder-modals/modals/SystemDialogModal.vue';

import HideItemButton from './-partials/HideItemButton.vue';

export default {
	components: {
		HideItemButton,
		SystemDialogModal,
	},
	props: {
		item: {
			type: Object,
			required: true,
		},
		toggle: {
			type: Function,
			default: () => ({}),
		},
	},
	data() {
		return { isDeleteModalShown: false };
	},
	methods: {
		...mapActions('navigation', ['removeItem']),
		remove() {
			this.removeItem({ itemId: this.item.id });
			this.isDeleteModalShown = false;
			this.toggle();
		},
	},
};
</script>

<style lang="scss" scoped>
@import './PopupButton';
</style>
