<template>
	<ZyroDrawer class="multipage-drawer">
		<MultipageDrawerContent />
	</ZyroDrawer>
</template>

<script>
import MultipageDrawerContent from '@/components/builder-controls/multipage-drawer/MultipageDrawerContent.vue';

export default { components: { MultipageDrawerContent } };
</script>

<style lang="scss" scoped>
.multipage-drawer {
	overflow: visible;
}
</style>
