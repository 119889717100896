<template>
	<ZyroPopup
		ref="settingsPopup"
		type="no-padding"
		class="popup"
		:show-close-button="false"
		:offset="popupOffset"
		:on-toggle="handlePopupToggle"
	>
		<template #trigger>
			<ZyroButton
				v-qa="`sitemenu-btn-settings-${item.name || item.id}`"
				class="popup__trigger"
				icon="settings"
				:title="$t('common.settings')"
			/>
		</template>
		<template #default="{ toggle }">
			<div @click.stop>
				<Component
					:is="buttonSetComponent"
					:item="item"
					class="popup__content"
					:toggle="toggle"
					@start-renaming="$emit('start-renaming')"
				/>
			</div>
		</template>
	</ZyroPopup>
</template>

<script>
import {
	NAVIGATION_PAGE_TYPE,
	NAVIGATION_LINK_TYPE,
	NAVIGATION_FOLDER_TYPE,
} from '@/use/navigation/constants';

import FolderButtons from './popup-buttons/FolderButtons.vue';
import LinkButtons from './popup-buttons/LinkButtons.vue';
import PageButtons from './popup-buttons/PageButtons.vue';

const POPUP_BUTTONS = {
	[NAVIGATION_PAGE_TYPE]: 'PageButtons',
	[NAVIGATION_LINK_TYPE]: 'LinkButtons',
	[NAVIGATION_FOLDER_TYPE]: 'FolderButtons',
};

const POPUP_TOP_POSITION = {
	top: 40,
	right: 0,
};
const POPUP_BOTTOM_POSITION = {
	bottom: 40,
	right: 0,
};

export default {
	components: {
		PageButtons,
		LinkButtons,
		FolderButtons,
	},
	props: {
		item: {
			type: Object,
			required: true,
		},
		onPopupOpen: {
			type: Function,
			default: () => null,
		},
		popupOpensToBottom: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		buttonSetComponent() {
			return POPUP_BUTTONS[this.item.type];
		},
		popupOffset() {
			return this.popupOpensToBottom ? POPUP_TOP_POSITION : POPUP_BOTTOM_POSITION;
		},
	},
	methods: {
		handlePopupToggle(isOpen, triggerPosition) {
			if (isOpen) {
				this.onPopupOpen(triggerPosition);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.popup {
	position: relative;

	.popup-card:not(.s) {
		min-width: 150px;
	}

	.popup__content:not(.s) {
		overflow: hidden;
		color: $dark;
	}

	.popup-card__content:not(.s) {
		display: flex;
		flex-direction: column;
	}

	&__trigger:not(.s) {
		overflow: hidden;
	}
}
</style>
