var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"items"},[_vm._t("header"),_c('div',{ref:"items",class:{ 'items-list-scrollable': _vm.isScrollable }},[_c('Draggable',{staticClass:"items-list-inner",class:{ 'items-list-inner--scrollable': _vm.isScrollable },attrs:{"tag":"ul","group":{ name: _vm.groupName },"data-group-id":_vm.groupId,"chosen-class":"item-ghost","ghost-class":"item-ghost","drag-class":"item-ghost-dragged","handle":".item__drag-handle","direction":"vertical","animation":"150","easing":"cubic-bezier(0.76, 0, 0.24, 1)","force-fallback":true,"inverted-swap-threshold":0.4,"swap-threshold":0.4,"empty-insert-threshold":4,"move":_vm.handleMove},on:{"end":_vm.handleDragEnd},model:{value:(_vm.currentItems),callback:function ($$v) {_vm.currentItems=$$v},expression:"currentItems"}},_vm._l((_vm.currentItems),function(item,index){return _c('li',{directives:[{name:"qa",rawName:"v-qa",value:(("editableitemlist-" + (item.name || item.id) + "-itemcontainer")),expression:"`editableitemlist-${item.name || item.id}-itemcontainer`"}],key:((item.name) + "-item-" + index),staticClass:"item-container",attrs:{"data-item-id":item.id || item.name}},[_c('div',{staticClass:"item",class:{
						'item--with-icon': item.icon,
						'item--disabled': item.isDisabled,
						'item--active': _vm.currentItemIndex === index,
						'item--selected': _vm.currentSelectedItemIndex === index,
						'item--before-active': _vm.currentItemIndex === index + 1
					},on:{"click":function($event){return _vm.$emit('item-click', item)}}},[(index === _vm.currentItemIndex)?_c('EditableItemsItemEditor',{attrs:{"value":_vm.inputValue,"placeholder":_vm.placeholder,"error":_vm.error},on:{"submit":_vm.editCurrentItem,"input":_vm.handleInput,"blur":_vm.stopEditing}}):[_c('ZyroButton',{staticClass:"item__drag-handle",attrs:{"theme":"plain","color":"black","icon":"move","title":_vm.$t('common.move')}}),(item.icon)?_c('ZyroSvg',{staticClass:"item__icon",attrs:{"location":item.iconLocation,"name":item.icon,"title":item.iconTitle}}):_vm._e(),_c('p',{directives:[{name:"qa",rawName:"v-qa",value:(("" + (item.vQa))),expression:"`${item.vQa}`"}],staticClass:"z-body-small item__content",class:{ 'z-body-small--strong': item.isCurrent },on:{"dblclick":function($event){return _vm.startEditingItem(index, item)}}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{class:{ 'item__right-side-button' : _vm.isScrollable }},[_vm._t("item-button",null,{"item":item,"index":index,"startEditingItem":function () { return _vm.startEditingItem(index, item); }})],2)]],2),(!item.hasNoSubList && item.subItems)?_c('div',{staticClass:"item-container__subitems"},[_c('EditableItemsList',_vm._g(_vm._b({scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(key,name){return {key:name,fn:function(slotData){return [_vm._t(name,null,null,slotData)]}}})],null,true)},'EditableItemsList',Object.assign({}, _vm.$props,
							{items: item.subItems,
							groupId: item.id}),false),_vm.$listeners))],1):_vm._e()])}),0)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }