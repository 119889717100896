<template>
	<div class="empty-block">
		<h3 class="empty-block__title z-body-small z-body-small--strong">
			{{ title }}
		</h3>
		<p class="z-body-small">
			{{ subtitle }}
		</p>
	</div>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			default: '',
		},
		subtitle: {
			type: String,
			default: '',
		},
	},
};
</script>

<style lang="scss" scoped>
.empty-block {
	max-width: 100%;
	padding: 16px;
	margin: 0 24px;
	color: $grey-600;
	text-align: center;
	border: 1px dashed $grey-400;

	.title {
		margin-bottom: 8px;
	}
}
</style>
